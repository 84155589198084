import React from 'react'
import MetaTitleDesc from '../MetaTitleDesc'
import { Container } from 'react-bootstrap';

const About = () => {
  return (

    <div>
      <MetaTitleDesc MetaTitle="About Us" MetaDesc="About Us" />
      <Container>


        <h1 className="fs-25 fw-700">About Us</h1>

        <p className="fs-17">Welcome to InvisibleText.co, your one-stop shop for creative text-hiding solutions. At InvisibleText.co, we specialize in solutions that allow users to easily conceal and produce invisible text. Our products are tailored to your specific requirements, whether you need to secure sensitive information, generate hidden messages, or add an additional degree of protection to your material.</p>

        <p className="fs-17">Our Invisible Text Generator is a powerful and user-friendly tool that enables you to create text that is invisible to the human sight but yet exists in digital space. This revolutionary tool is ideal for a variety of purposes, including data security, secure communication, and creative endeavors. Simply input your chosen text, and our generator will change it into invisible text, keeping your information secret and safe.</p>

        <p className="fs-17">We realize the value of privacy and security in the digital era, and our goal is to offer you with dependable and efficient solutions to help you protect your data. Our staff is committed to consistently developing our services and being at the cutting edge of text-hiding technology.</p>

        <p className="fs-17">At InvisibleText.co, we take pride in our dedication to client satisfaction. We attempt to deliver a seamless user experience, making our products simple to use and extremely effective. Our customer support staff is always there to help you with any queries or problems, ensuring that you get the most out of our services.</p>

        <p className="fs-17">Thank you for selecting InvisibleText.co. We are thrilled to be a part of your journey to secure and improve your digital communications. Explore our tools now and experience the magic of invisible text!</p>


      </Container>
    </div>
  )
}

export default About