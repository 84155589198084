import React from 'react';
import Layouts from './Layouts/Layouts'

const App = () => {
  return (
    <>
     <style>
        {`
          a {
            text-decoration: none;
            color: inherit;
          }
        `}
      </style>
      <div>
        <Layouts />
      </div>
    </>
  )
}

export default App