import React, { useState, useEffect } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const DE = () => {
  const [inputValue, setInputValue] = useState('1');
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    generateCharacters(newValue);
  };

  const generateCharacters = (value) => {
    const inputValueAsNumber = parseInt(value);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      setGeneratedText('');
    }
  };

  const handlePlusClick = () => {
    let newValue = parseInt(inputValue || '0') + 1;
    if (newValue > 10000) newValue = 10000;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const handleMinusClick = () => {
    let newValue = parseInt(inputValue || '1') - 1;
    if (newValue < 1) newValue = 1;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Your Invisible Text is Copied');
  };

  useEffect(() => {
    // try {
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (e) {
    //   console.error(e);
    // }
  }, []);

  const handleBookmarkClick = () => {
    alert('Press Ctrl+D to bookmark this page.');
  };

  return (
    <div>
      <MetaTitleDesc MetaTitle="Unsichtbarer Text ( ) Unsichtbares Zeichen [ ] Leerer Text Kopieren und Einfügen" MetaDesc="Erzeuge unsichtbaren Text mit spezialisierten Unicode-Zeichen, perfekt für WhatsApp, Discord und mehr. Kopiere und füge nahtlos mit unserem kostenlosen und benutzerfreundlichen Tool ein!" />
      <Container>
        <div className="row">
          <div className="col-md-9">
            <Alert variant="warning">
              <div className="text-center">
                <strong>INVISIBLE TEXT</strong> Copy and Paste
              </div>
            </Alert>
            <div className='mb-4'>
              <p className="fs-17">
              Unsichtbarer Text, unsichtbare Buchstaben und unsichtbare Zeichen sind wie Leerzeichen auf deinem Bildschirm. Sie existieren, sind aber nicht sichtbar und werden oft verwendet, wenn normale Leerzeichen nicht funktionieren. Dies sind Unicode-Zeichen, auch bekannt als unsichtbare Symbole, leerer Text und Leerzeichen
              </p>
              <h4 className="fs-18 fw-700">Unsichtbarer Textgenerator</h4>
              <p className="fs-17">Unten ist unser unsichtbarer Textgenerator. Du kannst auswählen, wie viele Leerzeichen du erzeugen möchtest, sie dann kopieren und überall einfügen, wo du möchtest.</p>
              {/* <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4007037816"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}
                <Card className="my-3">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="8">
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <ListGroup horizontal className='mb-3 justify-content-between align-items-center'>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-start'
                                onClick={handleMinusClick}
                              >
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0 w-100'>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  aria-label="invisibleText"
                                  className="shadow-none rounded-0 fs-17"
                                  aria-describedby="invisibleText"
                                  min="1"
                                  max="10000"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-end'
                                onClick={handlePlusClick}
                              >
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="name"
                          id="texto2"
                          className='shadow-none mb-3'
                          value={generatedText}
                          rows={4}
                          readOnly
                        />
                      </Form.Group>
                      <Button
                        variant="secondary"
                        className='mb-3 theme-btn border-0'
                        id="boton3"
                        value="Copiar"
                        onClick={copyToClipboard}
                      >
                        Copy
                      </Button>
                    </Col>
                  </Row>
                  <Card.Text>To copy invisible text with spaces, click the Copy button.</Card.Text>
                </Card.Body>
              </Card>
              {/* <ins class="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4154764519"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}

              <h4 className="fs-18 fw-700">Was ist unsichtbarer Text?</h4>
              <p className="fs-17">Unsichtbarer Text verwendet spezielle Unicode-Zeichen, die wie Leerzeichen aussehen. Diese Zeichen sind nützlich für:</p>
              <ul>
                <li>Versteckte Nachrichten senden</li>
                <li>Freunde veralbern</li>
                <li>Privatsphäre auf verschiedenen Plattformen wahren</li>
              </ul>
              <h4 className="fs-18 fw-700">Unsichtbaren Text kopieren</h4>
              <p className="fs-17">Um unsichtbaren Text zu kopieren, klicke auf die Schaltfläche Kopieren oder wähle den Text manuell aus und kopiere ihn. Dann kannst du ihn überall einfügen, wo du ihn benötigst.</p>
              <Row className="justify-content-center my-3">
                <Col md="8">
                  <div className="table-responsive">
                  <table className="table table-bordered">
                      <thead className='table-dark'>
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink to="/space/" className="text-theme">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink to="/no-break-space/" className="text-theme">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink to="/en-quad/" className="text-theme">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink to="/em-quad/" className="text-theme">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink to="/en-space/" className="text-theme">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink to="/em-space/" className="text-theme">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink to="/three-per-em-space/" className="text-theme">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink to="/four-per-em-space/" className="text-theme">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink to="/six-per-em-space/" className="text-theme">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink to="/figure-space/" className="text-theme">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink to="/punctuation-space/" className="text-theme">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink to="/thin-space/" className="text-theme">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink to="/hair-space/" className="text-theme">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink to="/line-separator/" className="text-theme">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink to="/medium-mathematical-space/" className="text-theme">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink to="/ideographic-space/" className="text-theme">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <h4 className="fs-18 fw-700">Wie benutzt man unsichtbaren Text?</h4>
              <h4 className="fs-18 fw-700">WhatsApp</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Whatsapp} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
              Sende leere Nachrichten, um deine Freunde zu überraschen. Es ist ein lustiger Trick, besonders an Tagen wie dem Aprilscherztag.
              </p>
              <h4 className="fs-18 fw-700">Discord</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Discord} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
              Verwende unsichtbaren Text, um anonym zu bleiben oder Freunde zu veralbern, ohne von den Administratoren bemerkt zu werden.
              </p>
              <h4 className="fs-18 fw-700">Free Fire</h4>
              <p className="fs-17">
              Erstelle einzigartige Namen mit unsichtbaren Leerzeichen, um im Spiel aufzufallen.
              </p>
              <h4 className="fs-18 fw-700">Steam</h4>
              <p className="fs-17">
              Bleibe unauffällig, indem du unsichtbare Zeichen in deinem Benutzernamen verwendest.
              </p>
              <h4 className="fs-18 fw-700">Fortnite</h4>
              <p className="fs-17">
              Verwende unsichtbare Zeichen, um deine Identität beim Spielen zu verbergen.
              </p>
              <h4 className="fs-18 fw-700">Twitter</h4>
              <p className="fs-17">
              Veröffentliche leere Tweets oder erstelle einzigartige Benutzernamen mit unsichtbarem Text.
              </p>
              <h4 className="fs-18 fw-700">YouTube</h4>
              <p className="fs-17">
              Verstecke deinen Markennamen oder füge unsichtbare Zeichen zu Titeln und Beschreibungen hinzu, um einen einzigartigen Touch zu verleihen.
              </p>
              <h4 className="fs-18 fw-700">Instagram</h4>
              <p className="fs-17">
              Sende leere Nachrichten und verbessere deine Beiträge mit unsichtbaren Texteffekten.
              </p>
              <h4 className="fs-18 fw-700">Roblox</h4>
              <p className="fs-17">
              Bleibe anonym, während du Inhalte postest, indem du unsichtbare Zeichen verwendest.
              </p>
              <h4 className="fs-18 fw-700">Wie funktioniert unsichtbarer Text?</h4>
              <p className="fs-17">
              Unsichtbarer Text besteht aus Unicode-Zeichen, die wie Leerzeichen aussehen. Auf iPhones kannst du die Funktion „Invisible Ink“ verwenden, um versteckte Nachrichten zu senden. Für andere Geräte verwende einen unsichtbaren Textgenerator, den du online findest.
              </p>
              <h4 className="fs-18 fw-700">Funktionen von unsichtbaren Textgeneratoren</h4>
              <ul>
                <li><strong>Einfach zu bedienen:  </strong> Kopiere und füge unsichtbaren Text mit nur wenigen Klicks ein.</li>
                <li><strong>Premium-Oberfläche:</strong> Benutzerfreundliches Design für einfache Navigation.</li>
                <li><strong>Mehrere Methoden:</strong>
                  <ul>
                    <li><strong>Methode 1:</strong> Klicke auf die Schaltfläche Kopieren, um den Text in deine Zwischenablage zu kopieren.</li>
                    <li><strong>Methode 2:</strong> Wähle den Text manuell aus, kopiere (Strg+C) und füge ihn ein.</li>
                  </ul>
                </li>
                <li><strong>Kostenlos:</strong> Keine Gebühren oder Kosten.</li>
                <li><strong>Mehrere Sprachen:</strong> Verfügbar in Englisch, Französisch, Portugiesisch und mehr.</li>
              </ul>
              <p className="fs-17">
              Unsichtbarer Text ermöglicht es dir, Nachrichten zu senden, die nur du und der Empfänger sehen können. Es bietet neue Kommunikationsmöglichkeiten und kann deine Sichtweise auf Online-Interaktionen verändern. Probiere es aus und entdecke die Möglichkeiten!
              </p>


            </div>
          </div>
          <div className="col-md-3">
            {/* <ins class="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6998527253520932"
              data-ad-slot="5731183025"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins> */}
              <Button
              variant="secondary"
              className='mb-3 w-100 d-block theme-btn border-0'
              onClick={handleBookmarkClick}
            >
              Bookmark this Tool
            </Button>
          </div>
        </div>
      </Container>

      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Invisible Text",
          "url": "https://invisibletext.co/",
          "potentialAction": {
            "@type": "SearchAction",
          "target": "https://invisibletext.co/search/{search_term_string}",
          "query-input": "required name=search_term_string"
            }
          }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Invisible Text",
        "url": "https://invisibletext.co/",
        "logo": ""
        }`}
      </script>

    </div>
  );
};

export default DE;
