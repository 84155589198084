import React from 'react'
import { NavLink } from 'react-router-dom';
// import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaGoogle, FaGithub, FaHome, FaPhone, FaPrint, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <footer className="text-center text-lg-start bg-light text-muted">
        <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
          <div className="row justify-content-around">
            <div className="col-md-6 text-start">
              © 2023 Copyright:
              <a className="text-reset fw-bold" href="/"> InvisibleText.co</a>
            </div>
            <div className="col-md-6 text-end">
              <ul className='m-0 p-0 list-unstyled d-flex justify-content-end gap-3'>
                <li><NavLink to="/about-us">About Us</NavLink></li>
                <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer