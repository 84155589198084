import React, { useState, useEffect } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const ES = () => {
  const [inputValue, setInputValue] = useState('1');
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    generateCharacters(newValue);
  };

  const generateCharacters = (value) => {
    const inputValueAsNumber = parseInt(value);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      setGeneratedText('');
    }
  };

  const handlePlusClick = () => {
    let newValue = parseInt(inputValue || '0') + 1;
    if (newValue > 10000) newValue = 10000;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const handleMinusClick = () => {
    let newValue = parseInt(inputValue || '1') - 1;
    if (newValue < 1) newValue = 1;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Your Invisible Text is Copied');
  };

  useEffect(() => {
    // try {
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (e) {
    //   console.error(e);
    // }
  }, []);

  const handleBookmarkClick = () => {
    alert('Press Ctrl+D to bookmark this page.');
  };

  return (
    <div>
      <MetaTitleDesc MetaTitle="Texto Invisible ( ) Carácter Invisible [ ] Texto en Blanco Copiar y Pegar" MetaDesc="Genera texto invisible utilizando caracteres Unicode especializados, ideal para WhatsApp, Discord y más. ¡Copia y pega sin esfuerzo con nuestra herramienta gratuita y fácil de usar!" />
      <Container>
        <div className="row">
          <div className="col-md-9">
            <Alert variant="warning">
              <div className="text-center">
                <strong>INVISIBLE TEXT</strong> Copy and Paste
              </div>
            </Alert>
            <div className='mb-4'>
              <p className="fs-17">
              El texto invisible, las letras invisibles y los caracteres invisibles son como espacios en blanco en tu pantalla. Existen, pero no son visibles y a menudo se usan cuando los espacios regulares no funcionan. Estos son caracteres Unicode, también conocidos como símbolos invisibles, texto vacío y espacios en blanco.
              </p>
              <h4 className="fs-18 fw-700">Generador de Texto Invisible</h4>
              <p className="fs-17">A continuación se muestra nuestro generador de texto invisible. Puedes elegir cuántos caracteres en blanco deseas producir, luego copiarlos y pegarlos donde quieras.</p>
              {/* <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4007037816"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}
                <Card className="my-3">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="8">
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <ListGroup horizontal className='mb-3 justify-content-between align-items-center'>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-start'
                                onClick={handleMinusClick}
                              >
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0 w-100'>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  aria-label="invisibleText"
                                  className="shadow-none rounded-0 fs-17"
                                  aria-describedby="invisibleText"
                                  min="1"
                                  max="10000"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-end'
                                onClick={handlePlusClick}
                              >
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="name"
                          id="texto2"
                          className='shadow-none mb-3'
                          value={generatedText}
                          rows={4}
                          readOnly
                        />
                      </Form.Group>
                      <Button
                        variant="secondary"
                        className='mb-3 theme-btn border-0'
                        id="boton3"
                        value="Copiar"
                        onClick={copyToClipboard}
                      >
                        Copy
                      </Button>
                    </Col>
                  </Row>
                  <Card.Text>To copy invisible text with spaces, click the Copy button.</Card.Text>
                </Card.Body>
              </Card>
              {/* <ins class="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4154764519"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}

              <h4 className="fs-18 fw-700">¿Qué es el texto invisible?</h4>
              <p className="fs-17">El texto invisible utiliza caracteres Unicode especiales que parecen espacios en blanco. Estos caracteres son útiles para:</p>
              <ul>
                <li>Enviar mensajes ocultos</li>
                <li>Bromear con amigos</li>
                <li>Mantener la privacidad en diferentes plataformas</li>
              </ul>
              <h4 className="fs-18 fw-700">Copiar texto invisible</h4>
              <p className="fs-17">Para copiar texto invisible, haz clic en el botón de Copiar o selecciona y copia el texto manualmente. Luego, puedes pegarlo donde lo necesites.</p>
              <Row className="justify-content-center my-3">
                <Col md="8">
                  <div className="table-responsive">
                  <table className="table table-bordered">
                      <thead className='table-dark'>
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink to="/space/" className="text-theme">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink to="/no-break-space/" className="text-theme">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink to="/en-quad/" className="text-theme">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink to="/em-quad/" className="text-theme">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink to="/en-space/" className="text-theme">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink to="/em-space/" className="text-theme">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink to="/three-per-em-space/" className="text-theme">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink to="/four-per-em-space/" className="text-theme">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink to="/six-per-em-space/" className="text-theme">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink to="/figure-space/" className="text-theme">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink to="/punctuation-space/" className="text-theme">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink to="/thin-space/" className="text-theme">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink to="/hair-space/" className="text-theme">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink to="/line-separator/" className="text-theme">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink to="/medium-mathematical-space/" className="text-theme">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink to="/ideographic-space/" className="text-theme">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <h4 className="fs-18 fw-700">Cómo usar el texto invisible</h4>
              <h4 className="fs-18 fw-700">WhatsApp</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Whatsapp} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
              Envía mensajes vacíos para sorprender a tus amigos. Es un truco divertido, especialmente en días como el Día de los Inocentes.
              </p>
              <h4 className="fs-18 fw-700">Discord</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Discord} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
              Usa texto invisible para mantener el anonimato o bromear con amigos sin ser notado por los administradores.
              </p>
              <h4 className="fs-18 fw-700">Free Fire</h4>
              <p className="fs-17">
              Crea nombres únicos con espacios invisibles para destacar en el juego.
              </p>
              <h4 className="fs-18 fw-700">Steam</h4>
              <p className="fs-17">
              Mantén un perfil bajo usando caracteres invisibles en tu nombre de usuario.
              </p>
              <h4 className="fs-18 fw-700">Fortnite</h4>
              <p className="fs-17">
              Usa caracteres invisibles para mantener tu identidad oculta mientras juegas.
              </p>
              <h4 className="fs-18 fw-700">Twitter</h4>
              <p className="fs-17">
              Publica tweets en blanco o crea nombres de usuario únicos con texto invisible.
              </p>
              <h4 className="fs-18 fw-700">YouTube</h4>
              <p className="fs-17">
              Oculta el nombre de tu marca o agrega caracteres invisibles a los títulos y descripciones para un toque único.
              </p>
              <h4 className="fs-18 fw-700">Instagram</h4>
              <p className="fs-17">
              Envía mensajes vacíos y mejora tus publicaciones con efectos de texto invisible.
              </p>
              <h4 className="fs-18 fw-700">Roblox</h4>
              <p className="fs-17">
              Mantén el anonimato al publicar contenido utilizando caracteres invisibles.
              </p>
              <h4 className="fs-18 fw-700">¿Cómo funciona el texto invisible?</h4>
              <p className="fs-17">
              El texto invisible está compuesto por caracteres Unicode que parecen espacios en blanco. En los iPhones, puedes usar la función de Tinta Invisible para enviar mensajes ocultos. Para otros dispositivos, usa un generador de texto invisible que puedes encontrar en línea.
              </p>
              <h4 className="fs-18 fw-700">Características de los generadores de texto invisible</h4>
              <ul>
                <li><strong>Fácil de usar:  </strong> Copia y pega texto invisible con solo unos pocos clics.</li>
                <li><strong>Interfaz premium:</strong> Diseño amigable para una fácil navegación.</li>
                <li><strong>Métodos múltiples:</strong>
                  <ul>
                    <li><strong>Método 1:</strong> Haz clic en el botón de copiar para copiar el texto en tu portapapeles.</li>
                    <li><strong>Método 2:</strong> Selecciona manualmente, copia (Ctrl+C) y pega el texto.</li>
                  </ul>
                </li>
                <li><strong>Gratuito:</strong> Sin cargos ni tarifas.</li>
                <li><strong>Múltiples idiomas:</strong> Disponible en inglés, francés, portugués y más.</li>
              </ul>
              <p className="fs-17">
              El texto invisible te permite enviar mensajes que solo tú y el destinatario pueden ver. Ofrece nuevas formas de comunicarte y puede cambiar la manera en que ves las interacciones en línea. ¡Pruébalo y descubre las posibilidades!
              </p>


            </div>
          </div>
          <div className="col-md-3">
            {/* <ins class="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6998527253520932"
              data-ad-slot="5731183025"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins> */}
              <Button
              variant="secondary"
              className='mb-3 w-100 d-block theme-btn border-0'
              onClick={handleBookmarkClick}
            >
              Bookmark this Tool
            </Button>
          </div>
        </div>
      </Container>

      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Invisible Text",
          "url": "https://invisibletext.co/",
          "potentialAction": {
            "@type": "SearchAction",
          "target": "https://invisibletext.co/search/{search_term_string}",
          "query-input": "required name=search_term_string"
            }
          }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Invisible Text",
        "url": "https://invisibletext.co/",
        "logo": ""
        }`}
      </script>

    </div>
  );
};

export default ES;
