import React, { useState, useEffect } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Home = () => {
  const [inputValue, setInputValue] = useState('1');
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    generateCharacters(newValue);
  };

  const generateCharacters = (value) => {
    const inputValueAsNumber = parseInt(value);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      setGeneratedText('');
    }
  };

  const handlePlusClick = () => {
    let newValue = parseInt(inputValue || '0') + 1;
    if (newValue > 10000) newValue = 10000;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const handleMinusClick = () => {
    let newValue = parseInt(inputValue || '1') - 1;
    if (newValue < 1) newValue = 1;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Your Invisible Text is Copied');
  };

  useEffect(() => {
    // try {
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (e) {
    //   console.error(e);
    // }
  }, []);

  const handleBookmarkClick = () => {
    alert('Press Ctrl+D to bookmark this page.');
  };

  return (
    <div>
      <MetaTitleDesc MetaTitle="Invisible Text ( ) Invisible Character [ ] Blank Text Copy and Paste" MetaDesc="Create invisible text using specialized Unicode characters, perfect for WhatsApp, Discord, and more. Copy and paste seamlessly with our free, user-friendly tool!" />
      <Container>
        <div className="row">
          <div className="col-md-9">
            <Alert variant="warning">
              <div className="text-center">
                <strong>INVISIBLE TEXT</strong> Copy and Paste
              </div>
            </Alert>
            <div className='mb-4'>
              <p className="fs-17">
                <strong>Invisible text, invisible letters,</strong> and <strong>invisible characters</strong> are like blank spaces on your screen. They exist but are not visible, often used when regular spaces won't work. These are <strong>Unicode characters</strong>, also known as <strong>invisible symbols, empty text,</strong> and <strong>white space</strong>.
              </p>
              <h4 className="fs-18 fw-700">Invisible Text Generator</h4>
              <p className="fs-17">Below is our invisible text generator. You can choose how many blank characters you want to produce, then copy them and paste them wherever you want.</p>
              {/* <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4007037816"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}
              <Card className="my-3">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="8">
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <ListGroup horizontal className='mb-3 justify-content-between align-items-center'>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-start'
                                onClick={handleMinusClick}
                              >
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0 w-100'>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  aria-label="invisibleText"
                                  className="shadow-none rounded-0 fs-17"
                                  aria-describedby="invisibleText"
                                  min="1"
                                  max="10000"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-end'
                                onClick={handlePlusClick}
                              >
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="name"
                          id="texto2"
                          className='shadow-none mb-3'
                          value={generatedText}
                          rows={4}
                          readOnly
                        />
                      </Form.Group>
                      <Button
                        variant="secondary"
                        className='mb-3 theme-btn border-0'
                        id="boton3"
                        value="Copiar"
                        onClick={copyToClipboard}
                      >
                        Copy
                      </Button>
                    </Col>
                  </Row>
                  <Card.Text>To copy invisible text with spaces, click the Copy button.</Card.Text>
                </Card.Body>
              </Card>
              {/* <ins class="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4154764519"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}

              <h4 className="fs-18 fw-700">What Is Invisible Text?</h4>
              <p className="fs-17"><strong>Invisible text </strong> uses special <strong>Unicode characters</strong> that look like blank spaces. These characters are useful for:</p>
              <ul>
                <li>Sending hidden messages</li>
                <li>Pranking friends</li>
                <li>Maintaining privacy on different platforms</li>
              </ul>
              <h4 className="fs-18 fw-700">Copying Invisible Text</h4>
              <p className="fs-17">To copy <strong>invisible text</strong>, click the <strong>Copy button</strong> or manually select and copy the text. You can then paste it anywhere you need.</p>
              <Row className="justify-content-center my-3">
                <Col md="8">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className='table-dark'>
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink to="/space/" className="text-theme">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink to="/no-break-space/" className="text-theme">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink to="/en-quad/" className="text-theme">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink to="/em-quad/" className="text-theme">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink to="/en-space/" className="text-theme">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink to="/em-space/" className="text-theme">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink to="/three-per-em-space/" className="text-theme">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink to="/four-per-em-space/" className="text-theme">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink to="/six-per-em-space/" className="text-theme">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink to="/figure-space/" className="text-theme">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink to="/punctuation-space/" className="text-theme">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink to="/thin-space/" className="text-theme">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink to="/hair-space/" className="text-theme">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink to="/line-separator/" className="text-theme">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink to="/medium-mathematical-space/" className="text-theme">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink to="/ideographic-space/" className="text-theme">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <h4 className="fs-18 fw-700">How to Use Invisible Text?</h4>
              <h4 className="fs-18 fw-700">WhatsApp</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Whatsapp} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
                Send empty messages to surprise friends. It's a fun trick, especially on days like <strong>April Fool's</strong>.
              </p>
              <h4 className="fs-18 fw-700">Discord</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Discord} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
                Use <strong>invisible text</strong> to stay anonymous or prank friends without getting noticed by  <strong>admins</strong>.
              </p>
              <h4 className="fs-18 fw-700">Free Fire</h4>
              <p className="fs-17">
                Create unique names with <strong>invisible spaces</strong> to stand out in the game.
              </p>
              <h4 className="fs-18 fw-700">Steam</h4>
              <p className="fs-17">
                Keep a low profile by using <strong>invisible characters</strong> in your username.
              </p>
              <h4 className="fs-18 fw-700">Fortnite</h4>
              <p className="fs-17">
                Use <strong>invisible characters</strong> to keep your identity hidden while playing.
              </p>
              <h4 className="fs-18 fw-700">Twitter</h4>
              <p className="fs-17">
                Post blank tweets or create unique usernames with <strong>invisible text.</strong>
              </p>
              <h4 className="fs-18 fw-700">YouTube</h4>
              <p className="fs-17">
                Hide your <strong>brand name</strong> or add <strong>invisible characters</strong> to titles and descriptions for a unique touch.
              </p>
              <h4 className="fs-18 fw-700">Instagram</h4>
              <p className="fs-17">
                Send blank messages and enhance your posts with <strong>invisible text</strong> effects.
              </p>
              <h4 className="fs-18 fw-700">Roblox</h4>
              <p className="fs-17">
                Stay anonymous while posting content by using <strong>invisible characters.</strong>
              </p>
              <h4 className="fs-18 fw-700">How Does Invisible Text Work?</h4>
              <p className="fs-17">
                <strong>Invisible text</strong> is made up of <strong>Unicode characters</strong> that look like blank spaces. On <strong>iPhones</strong>, you can use the <strong>Invisible Ink</strong> feature to send hidden messages. For other devices, use an <strong>invisible text generator</strong> found online.
              </p>
              <h4 className="fs-18 fw-700">Features of Invisible Text Generators</h4>
              <ul>
                <li><strong>Easy to Use:  </strong> Copy and paste <strong>invisible text</strong> with just a few clicks.</li>
                <li><strong>Premium Interface:</strong> User-friendly design for easy navigation.</li>
                <li><strong>Multiple Methods:</strong>
                  <ul>
                    <li><strong>Method 1:</strong> Click the <strong>copy button</strong> to copy text to your clipboard.</li>
                    <li><strong>Method 2:</strong> Manually select, copy (Ctrl+C), and paste the text.</li>
                  </ul>
                </li>
                <li><strong>Free to Use:</strong> No charges or fees.</li>
                <li><strong>Multiple Languages:</strong> Available in <strong>English, French, Portuguese,</strong> and more.</li>
              </ul>
              <p className="fs-17">
                <strong>Invisible text</strong> allows you to send messages that only you and the recipient can see. It offers new ways to communicate and can change how you view online interactions. Try it out and see the possibilities!
              </p>


            </div>
          </div>
          <div className="col-md-3">
            {/* <ins class="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6998527253520932"
              data-ad-slot="5731183025"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins> */}
              <Button
              variant="secondary"
              className='mb-3 w-100 d-block theme-btn border-0'
              onClick={handleBookmarkClick}
            >
              Bookmark this Tool
            </Button>
          </div>
        </div>
      </Container>

      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Invisible Text",
          "url": "https://invisibletext.co/",
          "potentialAction": {
            "@type": "SearchAction",
          "target": "https://invisibletext.co/search/{search_term_string}",
          "query-input": "required name=search_term_string"
            }
          }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Invisible Text",
        "url": "https://invisibletext.co/",
        "logo": ""
        }`}
      </script>

    </div>
  );
};

export default Home;
