import React, { useState, useEffect } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const RU = () => {
  const [inputValue, setInputValue] = useState('1');
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    generateCharacters(newValue);
  };

  const generateCharacters = (value) => {
    const inputValueAsNumber = parseInt(value);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      setGeneratedText('');
    }
  };

  const handlePlusClick = () => {
    let newValue = parseInt(inputValue || '0') + 1;
    if (newValue > 10000) newValue = 10000;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const handleMinusClick = () => {
    let newValue = parseInt(inputValue || '1') - 1;
    if (newValue < 1) newValue = 1;
    setInputValue(newValue.toString());
    generateCharacters(newValue);
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Your Invisible Text is Copied');
  };

  useEffect(() => {
    // try {
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (e) {
    //   console.error(e);
    // }
  }, []);

  const handleBookmarkClick = () => {
    alert('Press Ctrl+D to bookmark this page.');
  };

  return (
    <div>
      <MetaTitleDesc MetaTitle="Невидимый текст ( ) Невидимый символ [ ] Пустой текст Копировать и Вставить" MetaDesc="Создавайте невидимый текст с помощью специализированных символов Unicode, идеально подходит для WhatsApp, Discord и других приложений. Легко копируйте и вставляйте с помощью нашего бесплатного и удобного инструмента!" />
      <Container>
        <div className="row">
          <div className="col-md-9">
            <Alert variant="warning">
              <div className="text-center">
                <strong>INVISIBLE TEXT</strong> Copy and Paste
              </div>
            </Alert>
            <div className='mb-4'>
              <p className="fs-17">
              Невидимый текст, невидимые буквы и невидимые символы похожи на пустые пробелы на вашем экране. Они существуют, но не видны и часто используются, когда обычные пробелы не работают. Это символы Unicode, также известные как невидимые символы, пустой текст и пробелы.
              </p>
              <h4 className="fs-18 fw-700">Генератор Невидимого Текста</h4>
              <p className="fs-17">Ниже представлен наш генератор невидимого текста. Вы можете выбрать, сколько пустых символов хотите создать, затем скопировать их и вставить куда угодно.</p>
              {/* <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4007037816"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}
                <Card className="my-3">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="8">
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <ListGroup horizontal className='mb-3 justify-content-between align-items-center'>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-start'
                                onClick={handleMinusClick}
                              >
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0 w-100'>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  aria-label="invisibleText"
                                  className="shadow-none rounded-0 fs-17"
                                  aria-describedby="invisibleText"
                                  min="1"
                                  max="10000"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                            </ListGroup.Item>
                            <ListGroup.Item className='p-0 border-0'>
                              <Button
                                variant="secondary"
                                className='theme-btn border-2 border-warning rounded-0 rounded-end'
                                onClick={handlePlusClick}
                              >
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="name"
                          id="texto2"
                          className='shadow-none mb-3'
                          value={generatedText}
                          rows={4}
                          readOnly
                        />
                      </Form.Group>
                      <Button
                        variant="secondary"
                        className='mb-3 theme-btn border-0'
                        id="boton3"
                        value="Copiar"
                        onClick={copyToClipboard}
                      >
                        Copy
                      </Button>
                    </Col>
                  </Row>
                  <Card.Text>To copy invisible text with spaces, click the Copy button.</Card.Text>
                </Card.Body>
              </Card>
              {/* <ins class="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6998527253520932"
                data-ad-slot="4154764519"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins> */}

              <h4 className="fs-18 fw-700">Что такое невидимый текст?</h4>
              <p className="fs-17">Невидимый текст использует специальные символы Unicode, которые выглядят как пустые пробелы. Эти символы полезны для:</p>
              <ul>
                <li>Отправки скрытых сообщений</li>
                <li>Розыгрышей друзей</li>
                <li>Сохранения конфиденциальности на различных платформах</li>
              </ul>
              <h4 className="fs-18 fw-700">Копирование невидимого текста</h4>
              <p className="fs-17">Чтобы скопировать невидимый текст, нажмите кнопку "Копировать" или выберите и скопируйте текст вручную. Затем вы можете вставить его туда, где он вам нужен.</p>
              <Row className="justify-content-center my-3">
                <Col md="8">
                  <div className="table-responsive">
                  <table className="table table-bordered">
                      <thead className='table-dark'>
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink to="/space/" className="text-theme">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink to="/no-break-space/" className="text-theme">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink to="/en-quad/" className="text-theme">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink to="/em-quad/" className="text-theme">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink to="/en-space/" className="text-theme">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink to="/em-space/" className="text-theme">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink to="/three-per-em-space/" className="text-theme">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink to="/four-per-em-space/" className="text-theme">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink to="/six-per-em-space/" className="text-theme">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink to="/figure-space/" className="text-theme">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink to="/punctuation-space/" className="text-theme">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink to="/thin-space/" className="text-theme">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink to="/hair-space/" className="text-theme">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink to="/line-separator/" className="text-theme">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink to="/medium-mathematical-space/" className="text-theme">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink to="/ideographic-space/" className="text-theme">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <h4 className="fs-18 fw-700">Как использовать невидимый текст?</h4>
              <h4 className="fs-18 fw-700">WhatsApp</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Whatsapp} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
              Отправляйте пустые сообщения, чтобы удивить своих друзей. Это забавный трюк, особенно в такие дни, как День дураков.
              </p>
              <h4 className="fs-18 fw-700">Discord</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Discord} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">
              Используйте невидимый текст, чтобы оставаться анонимным или разыгрывать друзей, не привлекая внимания администраторов
              </p>
              <h4 className="fs-18 fw-700">Free Fire</h4>
              <p className="fs-17">
              Создавайте уникальные имена с невидимыми пробелами, чтобы выделиться в игре.
              </p>
              <h4 className="fs-18 fw-700">Steam</h4>
              <p className="fs-17">
              Оставайтесь незаметным, используя невидимые символы в вашем имени пользователя.
              </p>
              <h4 className="fs-18 fw-700">Fortnite</h4>
              <p className="fs-17">
              Используйте невидимые символы, чтобы скрыть свою личность во время игры.
              </p>
              <h4 className="fs-18 fw-700">Twitter</h4>
              <p className="fs-17">
              Публикуйте пустые твиты или создавайте уникальные имена пользователей с невидимым текстом.
              </p>
              <h4 className="fs-18 fw-700">YouTube</h4>
              <p className="fs-17">
              Скрывайте название своего бренда или добавляйте невидимые символы в заголовки и описания для уникальности.
              </p>
              <h4 className="fs-18 fw-700">Instagram</h4>
              <p className="fs-17">
              Отправляйте пустые сообщения и улучшайте свои публикации с помощью эффектов невидимого текста.
              </p>
              <h4 className="fs-18 fw-700">Roblox</h4>
              <p className="fs-17">
              Оставайтесь анонимным, публикуя контент, используя невидимые символы.
              </p>
              <h4 className="fs-18 fw-700">Как работает невидимый текст?</h4>
              <p className="fs-17">
              Невидимый текст состоит из символов Unicode, которые выглядят как пустые пробелы. На iPhone вы можете использовать функцию "Невидимые чернила" для отправки скрытых сообщений. Для других устройств используйте генератор невидимого текста, который можно найти онлайн.
              </p>
              <h4 className="fs-18 fw-700">Особенности генераторов невидимого текста</h4>
              <ul>
                <li><strong>Легко использовать:  </strong> Копируйте и вставляйте невидимый текст всего за несколько кликов.</li>
                <li><strong>Премиум интерфейс:</strong> Удобный дизайн для легкой навигации.</li>
                <li><strong>Множество методов:</strong>
                  <ul>
                    <li><strong>Метод 1:</strong> Нажмите кнопку копирования, чтобы скопировать текст в буфер обмена.</li>
                    <li><strong>Метод 2:</strong> Выберите текст вручную, скопируйте (Ctrl+C) и вставьте его.</li>
                  </ul>
                </li>
                <li><strong>Бесплатно:</strong> Без платы и сборов.</li>
                <li><strong>Несколько языков:</strong> Доступно на английском, французском, португальском и других языках.</li>
              </ul>
              <p className="fs-17">
              Невидимый текст позволяет вам отправлять сообщения, которые видите только вы и получатель. Он предлагает новые способы общения и может изменить ваше восприятие онлайн-взаимодействий. Попробуйте и откройте для себя новые возможности!
              </p>


            </div>
          </div>
          <div className="col-md-3">
            {/* <ins class="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6998527253520932"
              data-ad-slot="5731183025"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins> */}
              <Button
              variant="secondary"
              className='mb-3 w-100 d-block theme-btn border-0'
              onClick={handleBookmarkClick}
            >
              Bookmark this Tool
            </Button>
          </div>
        </div>
      </Container>

      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Invisible Text",
          "url": "https://invisibletext.co/",
          "potentialAction": {
            "@type": "SearchAction",
          "target": "https://invisibletext.co/search/{search_term_string}",
          "query-input": "required name=search_term_string"
            }
          }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Invisible Text",
        "url": "https://invisibletext.co/",
        "logo": ""
        }`}
      </script>

    </div>
  );
};

export default RU;
