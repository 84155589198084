import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaTitleDesc = ({ MetaTitle, MetaDesc, Translate }) => {

  let currentUrl = '';
  if (typeof window !== 'undefined') {
    currentUrl = window.location.href;
  }

  return (
    <div>
      <Helmet>
        <title translate={Translate}>{MetaTitle}</title>
        <meta name="description" translate={Translate} content={MetaDesc} />
        {/* {currentUrl && <link rel="canonical" href={currentUrl} />} */}
        {currentUrl && <meta property="og:url" content={currentUrl}/>}
      </Helmet>
    </div>
  );
};

export default MetaTitleDesc;
